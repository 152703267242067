@import "vars";

:root {
  --mainThemeColor: #f96611; // friendlyway orange svg logo
  //--mainThemeColor: #7ba352;  //canvaslogic green
}

::selection {
  background: transparent;
}

body {
  margin: 0;
  font-family: Rubik-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  min-height: 320px;
  color: $color-hover;
  font-size: $font-size-button;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

/************************************************************************/

header {
  padding: 0 10px;
  display: flex;
  align-items: center;
  background: #4CAF50;
  color: white;
  height: 40px;
}
.logo {
  justify-self: flex-start;
  margin-right: auto;
}
head a {
  color: white;
}
header a:visited {
  color: white;
}
header a+a {
  margin-left: 20px;
}
header a:last-of-type {
  margin-right: auto;
}

main {
  background: #DCE1E5;
  height: calc(100vh - 40px);
  padding: 10px;
}