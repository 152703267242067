@import "../../sass/vars";

.cl-text-button {
  color: $color-base;
  background-color: $color-selected-button;
  border-radius: $medium-corner-border-radius;
  box-sizing: border-box;
  &:hover {
    color: $color-hover;
  }
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

/** bordered */
.cl-text-button-bordered {
  border: 1px solid $color-border;
}

/** centered */
.cl-text-button-centered {
  margin-left: auto;
  margin-right: auto;
}

/** sizes */
.cl-text-button-medium {
  height: $base-button-size;
  padding-left: $base-space;
  padding-right: $base-space;
}

.cl-text-button-large {
  height: $large-button-size;
  padding-left: $large-space;
  padding-right: $large-space;
}