@import "../../sass/vars";
@import "../../sass/buttons";

.cl-imageUpload {
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  border: 2px dashed #3C3F457A;
  border-radius: 10px;
  font-size: $font-size-hint;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  background: #fff;

  .cl-imageUpload-disabled {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #fff;
  }

  .cl-imageUpload-drop {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  &.cl-imageUpload-over {
    border: 2px solid var(--mainThemeColor) !important;
  }

  &.cl-imageUpload-success {
    border-style: solid;
    border-color: #fff;
  }

  .cl-imageUpload-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;

    svg {
      margin-bottom: $base-space;
    }

    .cl-imageUpload-title {
      color: var(--mainThemeColor);
      font-size: 16px;
    }

    .cl-imageUpload-subTitle {
      color: black;
      font-size: 14px;
    }

    .cl-imageUpload-error {
      color: $color-error;

    }

    .cl-imageUpload-desc {
      font-size: $font-size-hint;
      margin-top: $base-space;
      padding: 0 $base-space*0.5;
    }
  }

  .cl-imageUpload-thumb {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
  }

  .cl-imageUpload-fileName {
    margin: $base-space;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cl-imageUpload-fileNameName {
      display: block;
      cursor: pointer;
      width: 100%;
      font-size: $font-size-hint;
      word-break: break-word;

      &.cl-imageUpload-fileNameNameDisabled {
        cursor: default;
      }
    }

    @include svg-hover;

    .cl-imageUpload-fileNameClose {
      @include icon-button;
      @include small-button-frame;
      margin-bottom: 0;
    }
  }
}


