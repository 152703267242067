@import "../sass/vars";

.cl-animatedHide {
  position: relative;
  transition: 0.5s linear all;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.cl-animatedHide-top {
    margin-top: -3*$large-button-size;
  }

  &.cl-animatedHide-right {
    margin-right: -3*$large-button-size;
  }

  &.cl-animatedHide-bottom {
    margin-bottom: -3*$large-button-size;
  }

  &.cl-animatedHide-left {
    margin-left: -3*$large-button-size;
  }
}