@import "vars";
@import "breakpoints";

/*********************************************************
* icon-button
*/
@mixin icon-button {
  width: $large-button-size;
  height: $large-button-size;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $color-background;
  div {
    justify-content: center;
    display: flex;
  }
}

@mixin small-button-frame {
  border-radius: $small-corner-border-radius;
  height: $small-button-size;
  width: $small-button-size;
  min-width: $small-button-size;
}

@mixin medium-button-frame {
  border-radius: $medium-corner-border-radius;
  height: $base-button-size;
  width: $base-button-size;
}

@mixin large-button-frame {
  border-radius: $large-corner-border-radius;
  width: $large-button-size;
  height: $large-button-size;
}

@mixin color-select-button {
  background-color:transparent;

  div {
    > * {
      box-sizing: border-box;
      transition: all ease-out 0.3s;
      @include medium-button-frame;
    }
    > img {
      border: $thin-dark-border;
    }

    &:hover {
      > * {
        @include large-button-frame;
      }
    }
  }
  &.cl-selected-tile {
    > div {
      > * {
        @include large-button-frame;
      }
    }
  }
}

@mixin render-control-button {

  @include svg-hover;
  @include large-button-frame;
  svg {
    width: $large-button-size;
    height: $large-button-size;
  }
}

@mixin rotate-button {
  @include svg-hover;
  @include medium-button-frame;

  margin-bottom: 0;
  svg {
    width: $base-button-size*1.4;
    height: $base-button-size*1.4;
  }
}

@mixin svg-hover {
  svg {
    .a, .b {
      fill: $color-base;
    }
    .c, .d {
      fill: var(--mainThemeColor);
    }
    &:hover {
      .a, .b {
        fill: $color-hover;
      }
    }
  }
}
