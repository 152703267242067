/*
* colors
*/
$color-base: rgba(60, 63, 69, 0.64);
$color-hover: rgba(60, 63, 69, 1);
$color-selected-button: #FAFAFA;
$color-background: #f5f5f5;
$color-popup-background: #3C3F45;
$color-disabled: rgba(60, 63, 69, 0.19);
$color-border: #e8e8e8;
$color-dark-border: $color-base;
$color-active: #bd3737;
$color-error: $color-active;
$color-input-default: #3A3F45;
$color-input-default-underline: #3C3F45A3;
$color-input-hover: $color-input-default;
$color-input-hover-underline: $color-input-default;
$color-input-active: $color-input-default;
$color-input-active-underline: $color-active;
$color-input-disabled: #3A3F453D;
$color-input-disabled-underline: $color-input-disabled;

/*
* app total height
*/
$app-total-height-min: 650px;
$app-total-height-max: 100vh;

/*
* app total width
*/
$app-total-width: 100%;

/*
* font-sizes and line heights
*/
$font-size-title1: 21px;
$font-size-title2: 18px;
$font-size-textarea: 20px;
$font-size-button: 16px;
$font-size-input: 14px;
$font-size-label: 12px;
$line-height-label: 18px;
$font-size-hint: 12px;

/*
* spaces
*/
$base-space: 6px;
$large-space: 20px;
$large-space-width: calc(100% - 40px);
$large-space-window-width: calc(100vw - 80px);

/*
* button sizes
*/
$small-button-size: 26px;
$medium-button-size: 38px;
$base-button-size: 38px;
//$base-button-size: 40px;
$large-icon-button-size: 40px;
$large-button-size: 56px;
$rotate-button-size: 75px;


/*
* corner
*/
$small-corner-border-radius: 8px;
$medium-corner-border-radius: 8px;
$large-corner-border-radius: 16px;

/*
* model-viewer height
*/
//$model-viewer-height-mobile: 424px;
//$model-viewer-height-mobile: 434px;
$model-viewer-height-mobile: 499px;

$model-viewer-height-desktop: 450px;

/*
* content width
*/
//$configuration-content-width: 260px;
//$configuration-content-width: 280px;
$configuration-content-width: 284px;
$configuration-min-width: 220px;

/*
* icon size
*/
$ar-link-icon-small-size: 186px;
$ar-link-icon-large-size: 200px;

/*
* aspect-ratio
*/
$aspect-ratio: 1.777778; // 16:9

/*
* shadows
*/
$box-shadow1: 2px 2px 16px rgba(0, 0, 0, 0.24);
$box-shadow2: -2px -2px 24px rgba(255, 255, 255, 1);
$box-shadow3: -4px -4px 24px rgba(255, 255, 255, 1);

/*
* borders / outlines
*/

$thin-dark-border: 1px solid $color-dark-border;


/*
* layout sizes
*/
$layout-left-width: 1fr;
$layout-right-width: $configuration-content-width+4*$large-space;

$popup-right-content-width: 260px;
$popup-right-inside-width: $popup-right-content-width - $large-space;
