@import "../../sass/buttons";

.cl-iconTextButton {
  font-size: $font-size-button;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  border-radius: $medium-corner-border-radius;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease;
  color: $color-base;
  height: $large-button-size;
  //margin: 0;
  margin: 0 2*$base-space 0 0;

  svg {
    display: inline-block;
    height: $base-button-size;
    width: $base-button-size;
    margin: $base-space;

    * {
      fill: $color-base
    }
  }

  &:last-of-type {
    margin-right: 0;
  }

  @include desktop {
    margin-right: 0;
    margin-bottom: 2 * $base-space;
    justify-content: start;
  }

  &.cl-button-shrink {
    height: $small-button-size + 4px; // 2*margin in svg
    margin-right: 1.5 * $base-space;
    margin-bottom: 18px; //keep .cl-app-configuration-container at same height
    svg {
      height: $small-button-size;
      width: $small-button-size;
      margin: 2px;
    }
    &:last-of-type{
      margin-right: 0;
    }

    @include mobile {
      height: $medium-button-size + 6px; // 2*margin in svg
      margin-right: 1.5 * $base-space;
      margin-bottom: 12px; //keep .cl-app-configuration-container at same height
      svg {
        height: $base-button-size;
        width: $base-button-size;
        margin: 2px;
      }
    }

    @include tablet {
      height: $large-button-size;
      width: $large-button-size;
      margin-right: 2 * $base-space;
      margin-bottom: 0;
      svg {
        margin: $base-space;
        height: $large-icon-button-size;
        width: $large-icon-button-size;
      }
    }
    @include desktop {
      width: 100%;
      margin-right: 0;
      margin-bottom: 2 * $base-space;
      svg {
        margin-right: 3*$base-space;
      }
    }
    @include desktop-shrink {
      height: $large-icon-button-size;
    }
  }

  &.cl-button-unselected.cl-button-available:hover {
    color: $color-hover;

    svg {
      * {
        fill: $color-hover;
      }
    }
  }

  &.cl-button-disabled {
    cursor: default;
    color: $color-disabled;

    svg {
      * {
        fill: $color-disabled;
      }
    }
  }

  &.cl-button-selected {
    color: var(--mainThemeColor);
    border-radius: $medium-corner-border-radius;
    background-color: $color-selected-button;
    box-shadow: $box-shadow1, $box-shadow2, $box-shadow3;

    svg {
      * {
        fill: var(--mainThemeColor);
      }
    }
  }

  .cl-iconTextButton-text {
    display: none;
  }

  @include desktop {
    margin-right: 0;
    pointer-events: painted;

    svg {
      margin-right: 3*$base-space;

    }
    .cl-iconTextButton-text {
      display: inline;
    }
  }
}
