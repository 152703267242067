@import "../../sass/vars";
@import "../../sass/keyframes";

@keyframes a0_t {
  0% {
    transform: translate(229px, 1507px);
  }
  23.077% {
    transform: translate(207px, 1485px);
  }
  100% {
    transform: translate(229px, 1507px);
  }
}

@keyframes a0_o {
  0% {
    opacity: .2;
  }
  11.538% {
    opacity: .2;
    animation-timing-function: cubic-bezier(.4, 0, 1, 1);
  }
  23.077% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes a0_w {
  0% {
    width: 12px;
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
  }
  23.077% {
    width: 56px;
  }
  100% {
    width: 12px;
  }
}

@keyframes a0_h {
  0% {
    height: 12px;
    animation-timing-function: cubic-bezier(0, 0, 1, 1);
  }
  23.077% {
    height: 56px;
  }
  100% {
    height: 12px;
  }
}

.cl-annotation-button {
  left: 0;
  top: 0;
  width: $large-button-size;
  height: $large-button-size;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  //background-image: url("../../assets/icons/images/red-circle.svg");
  animation: 400ms ease-in 0s 1 fadeIn;
  rect:first-of-type {
    opacity: 0;
  }

  &:hover {
    rect:first-of-type {
      opacity: 0.24;
      animation: 2.6s linear infinite both a0_t, 2.6s linear infinite both a0_o, 2.6s linear infinite both a0_w, 2.6s linear infinite both a0_h;
    }
    .cl-dummy-circle {opacity: 0}
  }
  .cl-annotation-buttonInner {
    position: relative;
    transform: translate(-50%, -50%);
    margin-top: 2px; // center red pulsing point
  }
}

.cl-annotation-button::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1;
}