@import "../sass/vars";
.cl-viewer-menu {
    display: flex;
    width: max-content;
    //position: absolute;
    gap: 2*$base-space;
}
.cl-viewer-menu-horizontal {
   flex-direction: row;
}
.cl-viewer-menu-vertical {
    flex-direction: column;
}