@import "../sass/vars";

/** initial overlay for mobile views */
.cl-grabbingOverlay {
    display: grid;
    justify-content: center;
    align-content: center;
    z-index: 1001;

/*  BTN CSS von canvaslogic website */
    .cl-btn {
        background: #7ba352;
        border-radius: 30px;
        padding: 16px 0;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        transition: .5s;
        text-decoration: none;
        display: flex;
        justify-content: center;
        width: 270px;
        //font-weight: 700;
        border: solid 2px #7ca353;
        font-family: Montserrat-Bold,sans-serif;
        font-size: 16px;
    }
}