@use "sass:math";
@import "../../sass/vars";
@import "../../sass/breakpoints";

.cl-arPopup {
  .cl-arPopup-container {
    gap: $large-space;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.cl-arPopup-server-info {
  width: $ar-link-icon-large-size *1.25;
  font-size: 14px;
}

.cl-arPopup-server-hint {
  font-size: $font-size-hint;
  width: $ar-link-icon-large-size *1.25;
}