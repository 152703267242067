.cl-overlay {
  position: absolute;
  z-index: 11;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.4);

  &.cl-overlay-transparent {
    background: rgba(255, 255, 255, 0);
  }
}
