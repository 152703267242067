@use "sass:math";
@import "../../sass/vars";
@import "../../sass/buttons";

.cl-viewport-close {
    @include icon-button;
    @include medium-button-frame;
    @include svg-hover;
    position: absolute;
    top: math.div($base-button-size, 2);
    right: math.div(-$base-button-size, 2);
    z-index: 1010;
}