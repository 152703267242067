@import "../sass/vars";
@import "../sass/breakpoints";

.cl-app {
    background-color: #fff;
    @include desktop {
        height: $app-total-height-max;
        //height: 100vh;
        min-height: $app-total-height-min;
        //max-height: $app-total-height-max;
    }
}

.cl-app-layout {
    margin: 0 auto;
    max-width: $app-total-width;
    display: grid;
    grid-template-columns: 1fr;
    //grid-template-rows: min(424px) min-content; // $model-viewer-height-mobile
    grid-template-rows: $model-viewer-height-mobile min-content; // $model-viewer-height-mobile
    position: relative;

    @include desktop {
        &.cl-app-fullsize {
            .cl-app-configuration {
                width: 0;
                overflow: hidden;
            }
        }
        grid-template-columns: $layout-left-width auto;
        overflow: hidden;
        transition: all linear 0.5s;
        grid-template-rows: minmax($model-viewer-height-desktop, 100%);
        min-height: 100%;
    }
}

/* grabbing space position */
.cl-app-grabbing-space {
    position: absolute;
    top: 15%;
    right: $large-space * 0.5;
    z-index: 10;
}

/* viewport section */
.cl-app-viewportViewer {
    position: relative;
    overflow: hidden;
    display: flex;
    background: transparent radial-gradient(closest-side at 50% 50%, rgba(217, 223, 227, 0.4) 0%, rgba(177, 194, 201, 0.4) 100%) 0 0 no-repeat padding-box;
}

/* configuration section */
.cl-app-configuration {
    transition: all linear 0.5s;
    background-color: $color-background;
    display: flex;
    position: relative;
    justify-content: center;
    @include desktop {
        width: $layout-right-width;
        overflow: hidden;
        .cl-app-configuration-container {
            width: $layout-right-width;
            position: absolute;
        }
    }
}

