@use "sass:math";
@import "../../sass/vars";
@import "../../sass/breakpoints";
@import "../../sass/buttons";
@import "../../sass/keyframes";

.cl-popup {
  position: absolute;
  height: 100%;
  width: 100%;
}

.cl-popup-popup {
  position: absolute;
  height: 100%;
  width: 100%;
  background: $color-popup-background;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: 150ms ease-in 0s 1 fadeIn;

  &.cl-popup-left {
    width: calc(100% - #{$layout-right-width});
  }

  &.cl-popup-right {
    right: 0;
    width: 100%;
    .cl-popup-inner{
      width: $configuration-content-width;
    }
  }

  &.cl-popup-full {
    width: 100%;
    .cl-popup-inner {
      margin: 2*$large-space;
    }
  }

  @include desktop {
    padding-top: 0;
    &.cl-popup-right {
      width: $layout-right-width;
    }
  }

  .cl-popup-inner {
    position: relative;
    height: 100%;
    box-sizing: border-box;
    padding: 2*$large-space 0;
    width: max-content;

    .cl-popup-content {
      height: 100%;
      //width: 100%;
    }
  }
}
