@import "../sass/vars";
@import "../sass/breakpoints";


/** configuration menu */
.cl-configuration-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .cl-configuration-menu {
    display: flex;
    flex-direction: row;
  }

  @include desktop {
    flex-direction: column;
    .cl-configuration-menu {
      display: block;
    }
  }
}