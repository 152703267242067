@use "sass:math";
@import "../../sass/vars";
@import "../../sass/breakpoints";

.cl-ar-link {
  display: block;
  width: $ar-link-icon-small-size;
  height: auto;
  transition: opacity 250ms ease-in;
  transition-delay: 250ms;
  text-decoration: none;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
 @include desktop {
   width: $ar-link-icon-large-size;
 }
}