@import "../sass/vars";
@import "../sass/breakpoints";

.cl-grab-handle {
  width: $base-button-size;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  i {
    color: rgba(255, 255, 255, 0.25) !important;
  }
  @include desktop {
    display: none;
  }
}

[class*="icono"] {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  &:after, &:before {
    content: "";
    box-sizing: border-box;
  }
}

.cl-icono-between {
  background-color: rgba(255, 255, 255, 0.25);
  width: 12px;
  height: 100%;
  position: absolute;
  top: 20px;
  left: 50%;
  margin-left: -6px;
  z-index: 111;
}

[class*="cl-icono-arrow"]{
  width: 0;
  height: 0;
  border-width: 14px;
  border-style: solid;
  border-bottom-color: transparent;
  border-left-color: transparent;
  margin: 6px;
  &[class*="-up"]{
    transform: rotate(-45deg);
  }
  &[class*="-down"]{
    transform: rotate(135deg) translate(100%, -100%);
  }
}