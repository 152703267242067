@import "../../sass/vars";

.cl-textInputTextAreaInput {
  border: 0;
  color: $color-input-default;
  border-bottom: $color-input-default-underline 1px solid;
  padding: 2px 0 1px 0;
  text-align: left;
  font-size: $font-size-input;
  outline-width: 0;
  background-color: rgba(255, 255, 255, 1);
  font-family: 'Rubik-Regular', sans-serif ;
  box-sizing: border-box;
  &:focus {
    outline: 0 solid transparent;
  }
  &:hover {
    color: $color-input-hover;
    border-bottom-color: $color-input-hover-underline;
  }
  &:focus {
    color: $color-input-active;
    border-bottom-color: $color-input-active-underline;
  }
  &:disabled {
    color: $color-input-disabled;
    border-bottom-color: $color-input-disabled-underline;
  }
  &::selection {
    background-color: var(--mainThemeColor)
  }
}