@import "../../sass/vars";
@import "../../sass/buttons";

.cl-start-debug-button {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--mainThemeColor);;
    height: 35px;
    width: 35px;
    div {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    svg{
        .b {
            fill: #fff;
        }
    }
}