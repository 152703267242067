@import "../sass/vars";
@import "../sass/breakpoints";

.cl-configurator-topLeftMenu {
  gap: $base-space !important;
  padding: $base-space*2.5 0 0 $base-space*2.5;
}

.cl-configurator-bottomLeftSpacer {
  bottom: 0;
  padding: 0 0 $large-space $base-space*2.5;
}

.cl-configurator-brush {
  //border: $thin-dark-border;
  //box-sizing: border-box;
  margin-bottom: 2*$base-space;
}

.cl-viewer-menu-horizontal {
  .cl-configurator-brush {
    margin-left: $large-space*0.5;
    margin-top: 0;
  }
}


/* configuration section */

.cl-configuration-area {
  display: grid;
  grid-template-rows: max-content auto;
  margin: $large-space 0 $large-space $large-space;

  &.cl-configuration-area-shrink {
    //margin: $large-space 0 $large-space 2px;
    margin: $large-space 0;
    .cl-configuration-menu{
      height: $large-button-size;
    }
    .cl-switch {
      margin-top: $base-space * (-0.5);
      @include mobile {
        margin-top: $base-space * (-1);
      }
      @include desktop {
        margin-top: 0;
      }
    }

    @include mobile {
      //margin: $large-space 0 $large-space $large-space;
    }
    @include desktop {
      margin: $large-button-size auto;
      .cl-configuration-menu{
        height: unset;
      }
    }
  }
  .cl-configuration-head {
    //display: none;
    z-index: 12;
    margin: 0 auto 3*$base-space auto;
  }
}

.cl-configuration-mainHeader {
  font-size: $font-size-title2;
  font-family: "Rubik-Medium", sans-serif;
  margin-bottom: $font-size-title2 * 0.5;
}

.cl-configuration-header {
  font-size: $font-size-title2;
  //font-family: "Rubik-Medium", sans-serif;
  margin-bottom: $font-size-title2;
  margin-top: $font-size-title2;
}

.cl-configuration-price {
  font-size: $font-size-title2;
}

@include desktop {
  .cl-configuration-area {
    margin: $large-button-size auto;
    width: $configuration-content-width;

    .cl-configuration-head {
      display: block;
      margin: 0 0 6*$base-space 0;
    }
  }
  .cl-configuration-mainHeader {
    font-size: $font-size-title1;
    margin-bottom: $font-size-title1 * 0.5;
  }
  .cl-configuration-price {
    font-size: $font-size-title1;
  }
}

/** configuration menu */
.cl-configuration-wrapper {
  .cl-switch {
    margin-top: $base-space * (-1);

    .cl-iconTextButton {
      margin-right: 0;
    }

    @include desktop {
      margin-top: 0;
      margin-bottom: 2 * $base-space;
      .cl-iconTextButton {
        margin-bottom: 0;
      }
    }
  }
}