@import "../../sass/vars";
@import "../../sass/buttons";

.cl-iconButton.cl-rotateButton {
  @include rotate-button;
}
.cl-iconButton.cl-rotateButton.cl-rotate-disabled {
  cursor: unset;
  &:hover {
    .a, .b {
      fill:  $color-base;
    }
  }
}