@import "../../sass/vars";

.cl-colorPicker-color {
  display: inline-flex;
  align-items: center;
  margin: $base-space 0;

  .cl-colorPicker-colorLabel {
    display: inline-block;
    margin-right: $base-space;
    font-size: $font-size-label;
    line-height: $line-height-label;
  }

  .cl-colorPicker-colorInput {
    display: inline-block;
  }
}

.react-colorful {
  width: $popup-right-inside-width !important;
  height: $popup-right-inside-width !important;
}
