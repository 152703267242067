@import "../../sass/vars";
@import "../../sass/breakpoints";

/**
  ar button
 */
.cl-arButton-container {
  width: $ar-link-icon-large-size;
  height: $ar-link-icon-large-size;
  position: relative;

  .cl-arButton-text {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding: $large-space;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .cl-arButton-text.cl-arButton-button {
    font-size: $font-size-textarea;
    color: $color-base;

    &:hover {
      color: $color-hover;
      cursor: pointer;
    }
  }

  .cl-arButton-text.cl-arButton-error {
    font-size: $font-size-hint;
    color: $color-error;
  }
}