@import "../sass/vars";
@import "../sass/breakpoints";

.cl-switch {
  box-shadow: $box-shadow1, $box-shadow2, $box-shadow3;
  border-radius: 12px;
  padding: 6px;
  border: 1px solid $color-border;
  height: min-content;
  display: flex;
  flex-direction: row;

  &.cl-switch-auto {
    @include desktop {
      flex-direction: column;
    }
  }

  &.cl-switch-vertical {
    flex-direction: column;
  }
}

.cl-switch-auto > * {
  margin-right: $base-space;

  &:last-child {
    margin-right: 0;
  }

  @include desktop {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.cl-switch-horizontal > * {
  margin-right: $base-space;

  &:last-child {
    margin-right: 0;
  }
}