@import "../sass/vars";
@import "../sass/buttons";

.cl-button-feedback-info {
  background: $color-background;
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $large-corner-border-radius;
  min-height: $large-button-size;
  padding: 0.5rem 1em;
  width: max-content;
  //max-width: calc(100vw - 100px);
  max-width: calc(100vw - 4 * #{$base-space} - #{$large-button-size} - #{$large-space
});
  box-sizing: border-box;

  .cl-button-feedback-info-text {
    width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  &.cl-button-feedback-info-selectable {
    .cl-button-feedback-info-text::selection {
      background-color: var(--mainThemeColor);
      color: white;
    }
  }

  @include desktop {
    max-width: calc(100vw - 4 * #{$base-space} - #{$large-button-size} - #{$large-space} - #{$layout-right-width});
  }
}


.cl-button-feedback-info-right {
  left: 2*$base-space + $large-button-size;
  bottom: 0;
}

.cl-button-feedback-info-top {
  left: 0;
  top: -60px;
}