@import "../../sass/buttons";
@import "../../sass/vars";
@import "../../sass/keyframes";

.cl-close-button {
  @include icon-button;
  @include medium-button-frame;
  @include svg-hover;
  top: $large-space;
  right: $large-space;
  position: absolute;
  z-index: 100;
  animation: 250ms ease-in 0s 1 fadeIn;
}