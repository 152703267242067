@use "sass:math";
@import "../../sass/vars";

.cl-lightboxPopup-switch {
  margin: $large-space 0;
}

.cl-lightboxPopup-text {
  width: $popup-right-inside-width;
  height: 70px;
  margin-bottom: $configuration-content-width - 70px;
}

.cl-lightboxPopup-image {
  width: $popup-right-inside-width !important;
  height: $popup-right-inside-width !important;
}