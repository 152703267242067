@import "../../sass/vars";

.cl-loading-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    //width: $large-button-size*2;
    //height: $large-button-size*2;
    border: 4px solid var(--mainThemeColor);
    border-radius: 50%;
    animation: cl-loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--mainThemeColor) transparent transparent transparent;
}
.cl-loading-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.cl-loading-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.cl-loading-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes cl-loading-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}