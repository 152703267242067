@import "../sass/keyframes";
@import "../sass/vars";
@import "../sass/breakpoints";

#cl-modelViewer {
  width: 100%;
  height: $model-viewer-height-mobile;
  position: absolute;
  .cl-canvas-initialize {
    animation: 1.5s ease-in 0s 1 fadeIn;
  }

  #cl_modelViewer_rotateLeft,
  #cl_modelViewer_rotateRight {
    position: absolute;
    left: 50%;
    bottom: $large-space;
    overflow: hidden;
    height: $base-button-size;
    width: $base-button-size;
    z-index: 20;
    margin-left: -1 * $base-button-size + $base-space;
  }
  #cl_modelViewer_rotateLeft {
    transform: translateX(-$large-space);
  }
  #cl_modelViewer_rotateRight {
    transform: translateX($base-button-size);
  }
  @include desktop {
    height: 100%;
  }

  @include rotateButton {
    #cl_modelViewer_rotateLeft.cl-responsive-button,
    #cl_modelViewer_rotateRight.cl-responsive-button {
      right: 3*$large-space !important;
      left: unset;
    }
  }
}