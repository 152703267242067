@import "../../sass/vars";
@import "../../sass/buttons";

.cl-assetsUpload {
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  border: 2px dashed #3C3F457A;
  border-radius: 10px;
  font-size: $font-size-hint;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  position: relative;
  background: #fff;

  .cl-assetsUpload-drop {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  &.cl-assetsUpload-over, &:hover {
    border: 2px solid var(--mainThemeColor) !important;
  }

  .cl-assetsUpload-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;

    svg {
      margin-bottom: $base-space;
    }

    .cl-assetsUpload-title {
      color: var(--mainThemeColor);
      font-size: 16px;
    }

    .cl-assetsUpload-desc {
      font-size: $font-size-hint;
      margin-top: $base-space;
    }
  }

  &.cl-assetsUpload-changed {
    border: 2px solid red;
    .cl-assetsUpload-title {
      color: red;
    }
  }
}


