$breakpoint-rotate-button: 450px;
$breakpoint-mobile: 390px;
$breakpoint-tablet: 510px;
$breakpoint-desktop: 820px;
$breakpoint-desktop-height: 610px;

@mixin rotateButton {
  @media (max-width: $breakpoint-rotate-button) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: $breakpoint-mobile) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin desktop-shrink {
  @media (min-width: $breakpoint-desktop) and (max-height: $breakpoint-desktop-height){
    @content;
  }
}