@import "../../sass/vars";
@import "../../sass/buttons";

.cl-share-feedback{
  position: relative;
  height: $large-button-size;
  width: $large-button-size;
  .cl-share-feedback-button {
    //animation: 250ms ease-in 0s 1 fadeIn;
    @include render-control-button;
    @include large-button-frame;
    @include svg-hover;

    .cl-loading-ring {
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width:  $small-button-size;
      height:  $small-button-size;
    }
  }
}
