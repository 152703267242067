@use "sass:math";
@import "../../sass/vars";
@import "../../sass/buttons";

.cl-confirm-button {
    @include icon-button;
    @include medium-button-frame;
    @include svg-hover;
    top: $large-space;
    right: $large-space*2;
    position: absolute;
    z-index: 100;
    animation: 250ms ease-in 0s 1 fadeIn;
}