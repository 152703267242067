@import "../../sass/vars";

.cl-popupContent {
  background-color: $color-background;
  border-radius: $small-corner-border-radius;
  animation: 350ms ease-in 0s 1 fadeIn;

  .cl-popupContent-inner {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: $large-space;
    position: relative;

    .cl-popupContent-title {
      font-size: $font-size-title2;
      margin-bottom: $base-space;
    }
  }
}
