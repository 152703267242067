@use "sass:math";
@import "../../sass/vars";

.cl-highres {
  //height: 100%;
  overflow: hidden;

  .cl-highres-imageWrapper,
  .cl-highres-loading {
    position: relative;
    border-radius: $medium-corner-border-radius;
    overflow: hidden;
  }

  .cl-highres-image {
    height: 100%;
    width: 100%;
  }

  .cl-highres-button {
    max-width: $popup-right-content-width;
  }

  .cl-highres-loading {
    transition: opacity 250ms ease-in;
    background-image: linear-gradient(#B1C2C9, #D9DFE3);
    position: absolute;
    overflow: hidden;
    display: block;
  }
}